<!--------HEADER FOR MOBILE VIEW-------->
 <section id="mobileHeader" class="mobileView">
    <div class="container-fluid primaryGradient">
        <div class="row mobileFixedHeader primaryGradient">
            <div class="col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3 mobileHeadPadT" data-toggle="modal" data-target="#mobileSideBar">
                <i class="fa fa-bars mMenuToggle " aria-hidden="true"></i>
            </div>
            <div class="col-8 col-sm-8 col-md-8 col-lg-6 col-xl-6 tCenter mobileHeadPadT">
                <img src="assets/img/Logo.png" class="mLogo" alt="Logo">&nbsp;&nbsp;
                <span *ngIf="warehouseData" class="warehouseName" (click)="changeWare()">{{warehouseData.wareHouseName}}&nbsp;
                    <i class="fa fa-pencil wareedit"></i>
                </span>
            </div>
            <div class="col-2 col-sm-2 col-md-2 col-lg-3 col-xl-3 tRight mobileHeadPadT" routerLink="/web/ag/profile">
                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            </div>
        </div>
    </div>
</section>
<div class="container-fluid primaryGradient mobileView" id="searchBar">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
            <div class="searchBar">
                <input type="text" placeholder="Search by product name" name="mobileSarch" [(ngModel)]="searchKeyMobile" (change)="gotoMobileSearch(searchKeyMobile)">
                <span class="secondThemeBG whiteClr" (click)="gotoMobileSearch(searchKeyMobile)">SEARCH
                </span>
            </div>
        </div>
        <div class="d-flex">
            <a href="https://play.google.com/store/apps/details?id=com.aggrocery_foodjunction">
                <img class="playstore-icon" src="assets/img/GooglePlay.png" alt="Logo">
            </a>
            <a href="https://apps.apple.com/in/app/food-junction-store/id1608325723">
                <img class="appstore-icon" src="assets/img/AppStore.png" alt="Logo">
            </a>
        </div>

    </div>
</div> 

<skeleton-loader *ngIf="isLoading"></skeleton-loader>

<!-- BACK TO TOP BUTTON -->
<a id="backToTopbutton"></a>

<!----desktop VIEW -->
<section class="homePageSection desktopView" *ngIf="previewDataList && previewDataList.length>0"
    (scroll)="onScrollOrder($event)">
    <ng-container>
        <section class="best-offer desktopView animated fadeDown" id="apna-grocery">
            <div class="container">
                <div class="row">
                    <div
                        [ngClass]="{'col-xl-12':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 1,'col-xl-6':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 2 }">
                        <ng-container *ngIf="promotedBrandView && promotedBrandView.cmsSectionBO  && promotedBrandView.cmsSectionBO.cmsSectionContentBOList
                                && promotedBrandView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
                            <div
                                *ngFor="let pageSection of promotedBrandView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                                <div *ngIf="pageSection.brandList && pageSection.brandList.length<4" class="row">
                                    <div *ngFor="let brand of pageSection.brandList;let itemIndex=index"
                                     [ngClass]="{'col-xl-3':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 1,
                                    'col-xl-4':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 2 }">
                                    <div class="single-brandDeskTop">
                                        <div class="brandListImage" routerLink="/web/ag/products"
                                            [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                                            <img class="card-img-top"
                                                src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-250,h-150,cm-pad_resize/{{brand.filePath}}"
                                                loading="lazy"
                                                onError="this.src='assets/img/brandOnError.png'">
                                        </div>
                                    </div>
                                    </div>

                                </div>
                                <div *ngIf="pageSection.brandList && pageSection.brandList.length>3">
                                    <owl-carousel-o [options]="apnagrocerySlider">
                                        <ng-container *ngFor="let brand of pageSection.brandList;let itemIndex=index">
                                            <ng-template carouselSlide>
                                                <div class="single-brandDeskTop">
                                                    <div class="brandListImage" routerLink="/web/ag/products"
                                                        [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                                                        <img class="card-img-top"
                                                            src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-250,h-150,cm-pad_resize/{{brand.filePath}}"
                                                            loading="lazy"
                                                            onError="this.src='assets/img/brandOnError.png'">   
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </section>

        <!-------HOME PAGE SLIDER------>
        <section class="mainSlider animated fadeIn" id="mainSlider"
            *ngIf="mainSlider?.cmsSlider?.cmsSliderContentList?.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pad0">
                        <br>
                        <owl-carousel-o [options]="advertisingSlider"
                            *ngIf="mainSlider.cmsSlider.cmsSliderContentList?.length > 1">
                            <ng-container *ngFor="let imgs of mainSlider.cmsSlider.cmsSliderContentList;let i=index">
                                <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                                    <div style="height: 443px;">
                                        <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}"
                                            loading="lazy" onError="this.src='assets/img/errorLogo.jpg'"
                                            style="width:100%;height: 100%;" (click)="onBannerClick(imgs)">
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>

                        <owl-carousel-o [options]="advertisingSlider"
                            *ngIf="mainSlider.cmsSlider.cmsSliderContentList?.length == 1">
                            <ng-container *ngFor="let imgs of mainSlider.cmsSlider.cmsSliderContentList;let i=index">
                                <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                                    <div style="height: 443px;">
                                        <img src="{{dataService.appConstant.IMAGE_URL}}{{mainSlider.cmsSlider.cmsSliderContentList[0].imageFilePath}}"
                                            loading="lazy" onError="this.src='assets/img/errorLogo.jpg'" loading="lazy"
                                            style="width:100%;height: 100%;" (click)="onBannerClick(imgs)">
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </section>

        <!-----DESKTOP  CATEGORY VIEW------->
        <div *ngIf="topCategoryView && topCategoryView.cmsSectionBO   && topCategoryView.cmsSectionBO.cmsSectionContentBOList
        && topCategoryView.cmsSectionBO.cmsSectionContentBOList.length > 0">
            <section class="pt-5 desktopView animated fadeIn"
                *ngFor="let pageSection of topCategoryView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                <div class="container" *ngIf="pageSection.catList?.length > 0">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                            <div class="top-categories">
                                <h3>{{pageSection.title}}</h3>
                                <p>{{pageSection.description}}
                                </p>
                                <div class="themeColor whiteBg ellipseBtn rectangleBannerBtn floatR"
                                    routerLink="/web/ag/categories">
                                    <b> All Categories &nbsp;&nbsp;
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    </b>
                                </div>
                                <br>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 product-category"
                                    *ngFor="let category of pageSection.catList | slice:0:4"
                                    routerLink="/web/ag/products"
                                    [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                                    <div class="card-product ">
                                        <div class="categoryImage">
                                            <img class="card-img-top"
                                                src="{{dataService.appConstant.IMAGE_URL}}{{category.filePath}}"
                                                loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                        </div>
                                        <div class="card-block-product" routerLink="/web/ag/products"
                                            [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                                            <h5 class="text-bold-product pt-1">{{category.name}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 product-category catCol"
                            *ngFor="let category of pageSection.catList | slice:4">
                            <div class="card-product" routerLink="/web/ag/products"
                                [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid}">
                                <div class="categoryImage">
                                    <img class="card-img-top"
                                        src="{{dataService.appConstant.IMAGE_URL}}{{category.filePath}}" loading="lazy"
                                        onError="this.src='assets/img/brandOnError.png'">
                                </div>
                                <div class="card-block-product">
                                    <h5 class="text-bold-product pt-1">{{category.name}} </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-----BRAND SECTION------->
        <div *ngIf="topBrandView && topBrandView.cmsSectionBO   && topBrandView.cmsSectionBO.cmsSectionContentBOList
        && topBrandView.cmsSectionBO.cmsSectionContentBOList.length > 0">
            <section class="shop-brands animated fadeDown mb-5" id="brand-slider"
                *ngFor="let pageSection of topBrandView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                <div class="container" *ngIf="pageSection.brandList">
                    <div class="bramd-title desktopView positionRelative">
                        <h3 id="titleColor">{{ pageSection.title}}</h3>
                        <span class="viewAll" routerLink="/web/ag/shop-by-brand">View All</span>
                    </div>

                    <div class="mobileVsectionHeading mobileView cp" routerLink="/web/ag/shop-by-brand">
                        <div class="sectionHeading">{{pageSection.title}}
                            <span>View All</span>
                        </div>
                    </div>

                    <div *ngIf="pageSection.brandList">
                        <!-- <owl-carousel-o [options]="advertisingSlider2"> -->
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
                                *ngFor="let brand of pageSection.brandList| slice:0:5">
                                <div class="brands" routerLink="/web/ag/products"
                                    [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                                    <img class="card-img-top"
                                        src="{{dataService.appConstant.IMAGE_URL}}{{brand.filePath}}" loading="lazy"
                                        onError="this.src='assets/img/brandOnError.png'">
                                </div>
                                <!-- <ng-template carouselSlide>

                                        </ng-template> -->
                            </div>
                        </div>
                        <!-- </owl-carousel-o> -->
                    </div>
                    <div *ngIf="pageSection.brandList" class="mt-3">
                        <!-- <owl-carousel-o [options]="advertisingSlider2"> -->
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2"
                                *ngFor="let brand of pageSection.brandList| slice:5">
                                <div class="brands" routerLink="/web/ag/products"
                                    [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                                    <img class="card-img-top"
                                        src="{{dataService.appConstant.IMAGE_URL}}{{brand.filePath}}" loading="lazy"
                                        onError="this.src='assets/img/brandOnError.png'">
                                </div>
                                <!-- <ng-template carouselSlide>
                                </ng-template> -->
                            </div>
                        </div>
                        <!-- </owl-carousel-o> -->
                    </div>
                </div>
                <div class="divider mobileView" *ngIf="!last"> </div>
            </section>
        </div>

        <!-------HOME PAGE SLIDER 2------>
        <ng-container>
            <section class="mainSlider animated fadeIn" id="mainSlider">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pad0">
                            <div *ngIf="bannerSectionView?.cmsSlider?.cmsSliderContentList?.length > 0">
                                <owl-carousel-o [options]="advertisingSlider"
                                    *ngIf="bannerSectionView.cmsSlider.cmsSliderContentList?.length > 1">
                                    <ng-container
                                        *ngFor="let imgs of bannerSectionView.cmsSlider.cmsSliderContentList;let i=index">
                                        <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                                            <div style="height: 443px;">
                                                <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}"
                                                    loading="lazy" onError="this.src='assets/img/errorLogo.jpg'"
                                                    style="width:100%;height: 100%;" (click)="onBannerClick(imgs)">
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ng-container>

        <!-----ITEM SECTION TOP HEADING ------>
        <div *ngIf="topProductView && topProductView.cmsSectionBO   && topProductView.cmsSectionBO.cmsSectionContentBOList
        && topProductView.cmsSectionBO.cmsSectionContentBOList.length > 0">

            <section class="best-offer desktopView animated fadeDown">
                <div class="container" id="best-offer-sec">
                    <!-- <div class="best-offer-title desktopView cp" [routerLink]="['/web/ag/products']"
                        [queryParams]="{brandName:pageSection.title,viewAll:viewAll}">
                        <h4>{{pageSection.title}} <span></span> <span>View All</span></h4>
                    </div>

                    <div class="mobileVsectionHeading mobileView" routerLink="/web/ag/products">
                        <div class="sectionHeading">{{pageSection.title}} <span>View All</span></div>
                    </div> -->
                    <div class="row">
                        <div [ngClass]="{'col-xl-12':topProductView.cmsSectionBO && topProductView.cmsSectionBO.noOfRows == 1,'col-xl-6':topProductView.cmsSectionBO && topProductView.cmsSectionBO.noOfRows == 2 }"
                            *ngFor="let pageSection of topProductView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                            <div class="best-offer-title desktopView cp">

                                <h4>{{pageSection.title}}
                                    <span></span>
                                    <span [routerLink]="['/web/ag/products']"
                                        [queryParams]="{brandName:pageSection.title,viewAll:viewAll,listType:pageSection.selectedItemFor,dataFilterDuration:pageSection.dataFilterDuration}"
                                        class="viewAll"
                                        *ngIf="pageSection.dataFilterDuration >0 || pageSection.selectedItemFor >0">View
                                        All</span>
                                </h4>
                            </div>

                            <div class="mobileVsectionHeading mobileView">
                                <div class="sectionHeading">{{pageSection.title}}
                                    <span [routerLink]="['/web/ag/products']"
                                        [queryParams]="{brandName:pageSection.title,viewAll:viewAll,listType:pageSection.selectedItemFor,dataFilterDuration:pageSection.dataFilterDuration}"
                                        class="viewAll"
                                        *ngIf="pageSection.dataFilterDuration >0 || pageSection.selectedItemFor >0">View
                                        All</span>
                                </div>
                            </div>
                            <div *ngIf="pageSection.itemList && pageSection.itemList.length<4" class="row mb-2">
                                <div *ngFor="let item of pageSection.itemList;let itemIndex=index" [ngClass]="{'col-xl-3':topProductView.cmsSectionBO && topProductView.cmsSectionBO.noOfRows == 1,
                                'col-xl-4':topProductView.cmsSectionBO && topProductView.cmsSectionBO.noOfRows == 2 }">
                                <div  class="card-product-offer single-product" >
                                    <div class="productListImage cursorPointer"
                                        [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                        (click)="itemDetail(item)">
                                        <div class="outOfStock grid-outofstock"
                                            *ngIf="!item.isStockAvailable">Out
                                            of Stock
                                        </div>
                                        <img class="card-img-top"
                                            src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                            loading="lazy" onError="this.src='assets/img/brandOnError.png'">

                                    </div>
                                    <div class="product-offer" *ngIf="item.discPerc">
                                        <p>{{item.discPerc}}% Off</p>
                                    </div>
                                    <div class="card-block-all-product">
                                        <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                            placement="top" show-delay="100">{{item.brandName}}</div>
                                        <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                            placement="top" show-delay="500" (click)="itemDetail(item)">
                                            {{item.itemName}}</h5>
                                        <p class="product-weight" (click)="itemDetail(item)">
                                            <span
                                                *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                            </span>
                                            <span
                                                *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                        </p>
                                        <div class="style-1 prodMrp">
                                            <del>
                                                <span
                                                    *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp  && (item.mrp > item.finalPrice)">
                                                    {{currencySymbol}} {{item.mrp}}
                                                </span>
                                                <span
                                                    *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice  && (item.salePrice > item.finalPrice)">
                                                    {{currencySymbol}} {{item.salePrice}}
                                                </span>
                                                <span
                                                    *ngIf="!item.discountPriceType  && (item.mrp > item.finalPrice)">
                                                    {{currencySymbol}} {{item.mrp}}
                                                </span>
                                            </del>
                                            <ins>
                                                <span class="money">
                                                    {{currencySymbol}} {{item.finalPrice}}
                                                </span>
                                            </ins>
                                        </div>
                                        <div>
                                        </div>
                                        <div
                                            *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                            <div *ngIf="!item.isAdded"
                                                (click)="addToCart(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                                class="ellipseBtn  positionRelative whiteClr addToCarLi rectangleBannerBtn secGradient">
                                                <b>
                                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    &nbsp;&nbsp; Add to cart</b>
                                                <div class="addToCartLoader" *ngIf="item.isLoading">
                                                    <img src="assets/img/spinner.gif">
                                                </div>
                                            </div>
                                            <div class="cartCount" *ngIf="item.isAdded">
                                                <div class="flex alignCenter contentJustify">
                                                    <div class="primaryGradient incrementDecrement"
                                                        (click)="updateCartQuantity(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="itemCount">
                                                        <b>{{item.quantity}}</b>
                                                    </div>
                                                    <div class="primaryGradient incrementDecrement"
                                                        (click)="updateCartQuantity(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">
                                            Out of Stock</div> -->
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div *ngIf="pageSection.itemList && pageSection.itemList.length>3">
                                <owl-carousel-o [options]="bestofferSlider">
                                    <ng-container *ngFor="let item of pageSection.itemList;let itemIndex=index">
                                        <ng-template carouselSlide>
                                            <div class="card-product-offer single-product">
                                                <div class="productListImage cursorPointer"
                                                    [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                                    (click)="itemDetail(item)">
                                                    <div class="outOfStock grid-outofstock"
                                                        *ngIf="!item.isStockAvailable">Out
                                                        of Stock
                                                    </div>
                                                    <img class="card-img-top"
                                                        src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                                        loading="lazy" onError="this.src='assets/img/brandOnError.png'">

                                                </div>
                                                <div class="product-offer" *ngIf="item.discPerc">
                                                    <p>{{item.discPerc}}% Off</p>
                                                </div>
                                                <div class="card-block-all-product">
                                                    <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                                        placement="top" show-delay="100">{{item.brandName}}</div>
                                                    <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                                        placement="top" show-delay="500" (click)="itemDetail(item)">
                                                        {{item.itemName}}</h5>
                                                    <p class="product-weight" (click)="itemDetail(item)">
                                                        <span
                                                            *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                                        </span>
                                                        <span
                                                            *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                                    </p>
                                                    <div class="style-1 prodMrp">
                                                        <del>
                                                            <span
                                                                *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp  && (item.mrp > item.finalPrice)">
                                                                {{currencySymbol}} {{item.mrp}}
                                                            </span>
                                                            <span
                                                                *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice  && (item.salePrice > item.finalPrice)">
                                                                {{currencySymbol}} {{item.salePrice}}
                                                            </span>
                                                            <span
                                                                *ngIf="!item.discountPriceType  && (item.mrp > item.finalPrice)">
                                                                {{currencySymbol}} {{item.mrp}}
                                                            </span>
                                                        </del>
                                                        <ins>
                                                            <span class="money">
                                                                {{currencySymbol}} {{item.finalPrice}}
                                                            </span>
                                                        </ins>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    <div
                                                        *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                                        <div *ngIf="!item.isAdded"
                                                            (click)="addToCart(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                                            class="ellipseBtn  positionRelative whiteClr addToCarLi rectangleBannerBtn secGradient">
                                                            <b>
                                                                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                                &nbsp;&nbsp; Add to cart</b>
                                                            <div class="addToCartLoader" *ngIf="item.isLoading">
                                                                <img src="assets/img/spinner.gif">
                                                            </div>
                                                        </div>
                                                        <div class="cartCount" *ngIf="item.isAdded">
                                                            <div class="flex alignCenter contentJustify">
                                                                <div class="primaryGradient incrementDecrement"
                                                                    (click)="updateCartQuantity(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                                </div>
                                                                <div class="itemCount">
                                                                    <b>{{item.quantity}}</b>
                                                                </div>
                                                                <div class="primaryGradient incrementDecrement"
                                                                    (click)="updateCartQuantity(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">
                                                        Out of Stock</div> -->
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

           <!-----BEST SELLER SECTION TOP HEADING ------>
           <div class="pt-5" *ngIf="bestSellerProduct && bestSellerProduct.cmsSectionBO && bestSellerProduct.cmsSectionBO.cmsSectionContentBOList
           && bestSellerProduct.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
               <section class="pt-5 best-offers-product desktopView animated fadeDown" id="special-offer-sec"
                   *ngFor="let pageSection of bestSellerProduct.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                   <div class="container">
                       <!-- <div class="best-offer-title mobileView cp" routerLink="/web/ag/products">
                           <h4>{{ pageSection.title}} <span [routerLink]="['/web/ag/products']"
                                   [queryParams]="{brandName:pageSection.title,viewAll:viewAll}">View All</span></h4>
                       </div> -->
   
                       <div class="row">
                           <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 ">
                               <div class="best-seller positionRelative desktopView ">
                                   <h3>{{pageSection.title}}</h3>
                                   <p>{{pageSection.description}}
                                   </p>
                                   <!-- <div class="whiteBg ellipseBtn verticalEllipse secondThemeColor rectangleAbsolute"
                                       routerLink="/web/ag/products"
                                       [queryParams]="{viewAll:viewAll,cmsSectionContentId:pageSection.cmsSectionContentId}">
                                       <b> View all &nbsp;&nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i></b>
                                   </div> -->
                               </div>
                           </div>
   
                           <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                               <div *ngIf="pageSection.itemList && pageSection.itemList.length<4" class="row mb-2">
                                   <div class="col-12 col-lg-3 col-xl-3 col-xxl-3 px-0" *ngFor="let item of pageSection.itemList;let itemIndex=index">
                                   <div class="card-product-offer single-product">
                                       <div class="productListImage"
                                           [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                           (click)="itemDetail(item)">
                                           <div class="productListImage">
   
                                               <div class="outOfStock mobview-offer-outofstock"
                                                   *ngIf="!item.isStockAvailable">
                                                   Out of Stock</div>
                                               <img class="card-img-top"
                                                   src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                                   loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                           </div>
                                       </div>
                                       <div class="product-offer" *ngIf="item.discPerc">
                                           <p>{{item.discPerc}}% Off</p>
                                       </div>
                                       <div class="card-block-all-product">
                                           <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                               placement="top" show-delay="100">{{item.brandName}}</div>
   
   
                                           <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                               placement="top" show-delay="500" (click)="itemDetail(item)">
                                               {{item.itemName}}</h5>
   
                                           <p class="product-weight" (click)="itemDetail(item)">
                                               <span
                                                   *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                               </span>
                                               <span
                                                   *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                           </p>
                                           <div class="style-1">
                                               <del>
                                                   <span
                                                       *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                                       {{currencySymbol}} {{item.mrp}}
                                                   </span>
                                                   <span
                                                       *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                                       {{currencySymbol}} {{item.salePrice}}
                                                   </span>
                                                   <span
                                                       *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice) ">
                                                       {{currencySymbol}} {{item.mrp}}
                                                   </span>
                                               </del>
                                               <ins>
                                                   <span class="money">
                                                       {{currencySymbol}} {{item.finalPrice}}
                                                   </span>
                                               </ins>
                                           </div>
                                           <div>
                                           </div>
   
                                           <div *ngIf="item.isStockAvailable">
                                               <div *ngIf="!item.isAdded"
                                                   (click)="addToCart(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                                   class="ellipseBtn  whiteClr  positionRelative addToCarLiMd rectangleBannerBtn secGradient">
                                                   <b>
                                                       <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                       &nbsp;&nbsp; Add to cart</b>
                                                   <div class="addToCartLoader" *ngIf="item.isLoading">
                                                       <img src="assets/img/spinner.gif">
                                                   </div>
                                               </div>
                                               <div class="cartCount" *ngIf="item.isAdded">
                                                   <div class="flex alignCenter contentJustify">
                                                       <div class="primaryGradient incrementDecrement"
                                                           (click)="updateCartQuantity(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                           <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                       </div>
                                                       <div class="itemCount">
                                                           <b>{{item.quantity}}</b>
                                                       </div>
                                                       <div class="primaryGradient incrementDecrement"
                                                           (click)="updateCartQuantity(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                           <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div>
                                           <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">
                                               Out of Stock
                                           </div> -->
   
                                       </div>
                                   </div>
                                   </div>
                               </div>
                               <div *ngIf="pageSection.itemList && pageSection.itemList.length>3">
                               <owl-carousel-o [options]="bestofferSlider">
                                   <ng-container *ngFor="let item of pageSection.itemList;let itemIndex=index">
                                       <ng-template carouselSlide>
                                           <div class="card-product-offer single-product">
                                               <div class="productListImage"
                                                   [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                                   (click)="itemDetail(item)">
                                                   <div class="productListImage">
   
                                                       <div class="outOfStock mobview-offer-outofstock"
                                                           *ngIf="!item.isStockAvailable">
                                                           Out of Stock</div>
                                                       <img class="card-img-top"
                                                           src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                                           loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                                   </div>
                                               </div>
                                               <div class="product-offer" *ngIf="item.discPerc">
                                                   <p>{{item.discPerc}}% Off</p>
                                               </div>
                                               <div class="card-block-all-product">
                                                   <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                                       placement="top" show-delay="100">{{item.brandName}}</div>
   
   
                                                   <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                                       placement="top" show-delay="500" (click)="itemDetail(item)">
                                                       {{item.itemName}}</h5>
   
                                                   <p class="product-weight" (click)="itemDetail(item)">
                                                       <span
                                                           *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                                       </span>
                                                       <span
                                                           *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                                   </p>
                                                   <div class="style-1">
                                                       <del>
                                                           <span
                                                               *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                                               {{currencySymbol}} {{item.mrp}}
                                                           </span>
                                                           <span
                                                               *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                                               {{currencySymbol}} {{item.salePrice}}
                                                           </span>
                                                           <span
                                                               *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice) ">
                                                               {{currencySymbol}} {{item.mrp}}
                                                           </span>
                                                       </del>
                                                       <ins>
                                                           <span class="money">
                                                               {{currencySymbol}} {{item.finalPrice}}
                                                           </span>
                                                       </ins>
                                                   </div>
                                                   <div>
                                                   </div>
   
                                                   <div *ngIf="item.isStockAvailable">
                                                       <div *ngIf="!item.isAdded"
                                                           (click)="addToCart(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                                           class="ellipseBtn  whiteClr  positionRelative addToCarLiMd rectangleBannerBtn secGradient">
                                                           <b>
                                                               <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                               &nbsp;&nbsp; Add to cart</b>
                                                           <div class="addToCartLoader" *ngIf="item.isLoading">
                                                               <img src="assets/img/spinner.gif">
                                                           </div>
                                                       </div>
                                                       <div class="cartCount" *ngIf="item.isAdded">
                                                           <div class="flex alignCenter contentJustify">
                                                               <div class="primaryGradient incrementDecrement"
                                                                   (click)="updateCartQuantity(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                                   <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                               </div>
                                                               <div class="itemCount">
                                                                   <b>{{item.quantity}}</b>
                                                               </div>
                                                               <div class="primaryGradient incrementDecrement"
                                                                   (click)="updateCartQuantity(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                                   <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>
                                                   <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">
                                                       Out of Stock
                                                   </div> -->
   
                                               </div>
                                           </div>
                                       </ng-template>
                                   </ng-container>
                               </owl-carousel-o>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>
           </div>

           <!-------HOME PAGE SLIDER 3------>
        <section class="mainSlider animated fadeIn mt-5" id="mainSlider">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pad0">
                        <div *ngIf="bannerSection3?.cmsSlider?.cmsSliderContentList?.length > 0">
                            <owl-carousel-o [options]="advertisingSlider"
                                *ngIf="bannerSection3.cmsSlider.cmsSliderContentList?.length > 1">
                                <ng-container
                                    *ngFor="let imgs of bannerSection3.cmsSlider.cmsSliderContentList;let i=index">
                                    <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                                        <div style="height: 443px;">
                                            <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}"
                                                loading="lazy" onError="this.src='assets/img/errorLogo.jpg'"
                                                style="width:100%;height: 100%;" (click)="onBannerClick(imgs)">
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!------WEBSITE PRODUCT AND RECOMMANDATION PRODUCT-->
        <section class="best-offer desktopView animated fadeDown" id="apna-grocery"
         *ngIf="(websiteProduct && websiteProduct.cmsSectionBO) || (recommendationView && recommendationView.cmsSectionBO)">
         <div class="container">
             <div class="row">
                 <div 
                      [ngClass]="{'col-xl-12':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 1,'col-xl-6':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 2 }">
                     <ng-container *ngIf="websiteProduct && websiteProduct.cmsSectionBO  && websiteProduct.cmsSectionBO.cmsSectionContentBOList
                     && websiteProduct.cmsSectionBO.cmsSectionContentBOList.length > 0 ">

                         <div
                             *ngFor="let pageSection of websiteProduct.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                             <h3 class="recent-product-title">{{ pageSection.title}}
                             </h3>
                             <div *ngIf="pageSection.itemList && pageSection.itemList.length<4" class="row mb-2">
                              <div *ngFor="let item of pageSection.itemList;let itemIndex=index"
                              [ngClass]="{'col-xl-3':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 1,
                              'col-xl-4':websiteProduct.cmsSectionBO && websiteProduct.cmsSectionBO.noOfRows == 2 }">
                              <div class="card-product-offer single-product">
                                 <div class="productListImage"
                                     [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                     (click)="itemDetail(item)">
                                     <div class="productListImage">
                                         <div class="outOfStock list-offer-outofstock"
                                             *ngIf="!item.isStockAvailable">Out of Stock
                                         </div>
                                         <img class="card-img-top"
                                             src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                             loading="lazy"
                                             onError="this.src='assets/img/brandOnError.png'">
                                     </div>
                                 </div>
                                 <div class="product-offer" *ngIf="item.discPerc">
                                     <p>{{item.discPerc}}% Off</p>
                                 </div>
                                 <div class="card-block-all-product">
                                     <!-- <div class="brandName">{{item.brandName}}</div> -->
                                     <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                         placement="top" show-delay="100">{{item.brandName}}</div>

                                     <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                         placement="top" show-delay="500" (click)="itemDetail(item)">
                                         {{item.itemName}}</h5>
                                     <p class="product-weight">
                                         <span
                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                         </span>
                                         <span
                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                     </p>
                                     <div class="style-1">
                                         <del>
                                             <span
                                                 *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                                 {{currencySymbol}} {{item.mrp}}
                                             </span>
                                             <span
                                                 *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                                 {{currencySymbol}} {{item.salePrice}}
                                             </span>
                                             <span
                                                 *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice)">
                                                 {{currencySymbol}} {{item.mrp}}
                                             </span>
                                         </del>
                                         <ins>
                                             <span class="money">
                                                 {{currencySymbol}} {{item.finalPrice}}
                                             </span>
                                         </ins>
                                     </div>
                                     <div>
                                     </div>
                                     <div
                                         *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                         <div *ngIf="!item.isAdded"
                                             (click)="addToCart(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                             class="ellipseBtn  whiteClr positionRelative addToCarLiMd halfViewBtn secGradient">
                                             <b>
                                                 <i class="fa fa-shopping-cart"
                                                     aria-hidden="true"></i>
                                                 &nbsp;&nbsp; Add to cart</b>
                                             <div class="addToCartLoader" *ngIf="item.isLoading">
                                                 <img src="assets/img/spinner.gif">
                                             </div>
                                         </div>
                                         <div class="cartCount" *ngIf="item.isAdded">
                                             <div class="flex alignCenter contentJustify">
                                                 <div class="primaryGradient incrementDecrement"
                                                     (click)="updateCartQuantity(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                     <i class="fa fa-angle-left"
                                                         aria-hidden="true"></i>
                                                 </div>
                                                 <div class="itemCount">
                                                     <b>{{item.quantity}}</b>
                                                 </div>
                                                 <div class="primaryGradient incrementDecrement"
                                                     (click)="updateCartQuantity(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                     <i class="fa fa-angle-right"
                                                         aria-hidden="true"></i>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">Out of Stock
                                     </div> -->
                                 </div>
                             </div>
                              </div>
                             </div>
                             <div *ngIf="pageSection.itemList && pageSection.itemList.length>3">
                                 <owl-carousel-o [options]="apnagrocerySlider">
                                     <ng-container *ngFor="let item of pageSection.itemList;let itemIndex=index">
                                         <ng-template carouselSlide>
                                             <div class="card-product-offer single-product">
                                                 <div class="productListImage"
                                                     [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                                     (click)="itemDetail(item)">
                                                     <div class="productListImage">
                                                         <div class="outOfStock list-offer-outofstock"
                                                             *ngIf="!item.isStockAvailable">Out of Stock
                                                         </div>
                                                         <img class="card-img-top"
                                                             src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                                             loading="lazy"
                                                             onError="this.src='assets/img/brandOnError.png'">
                                                     </div>
                                                 </div>
                                                 <div class="product-offer" *ngIf="item.discPerc">
                                                     <p>{{item.discPerc}}% Off</p>
                                                 </div>
                                                 <div class="card-block-all-product">
                                                     <!-- <div class="brandName">{{item.brandName}}</div> -->
                                                     <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                                         placement="top" show-delay="100">{{item.brandName}}</div>

                                                     <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                                         placement="top" show-delay="500" (click)="itemDetail(item)">
                                                         {{item.itemName}}</h5>
                                                     <p class="product-weight">
                                                         <span
                                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                                         </span>
                                                         <span
                                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                                     </p>
                                                     <div class="style-1">
                                                         <del>
                                                             <span
                                                                 *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                                                 {{currencySymbol}} {{item.mrp}}
                                                             </span>
                                                             <span
                                                                 *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                                                 {{currencySymbol}} {{item.salePrice}}
                                                             </span>
                                                             <span
                                                                 *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice)">
                                                                 {{currencySymbol}} {{item.mrp}}
                                                             </span>
                                                         </del>
                                                         <ins>
                                                             <span class="money">
                                                                 {{currencySymbol}} {{item.finalPrice}}
                                                             </span>
                                                         </ins>
                                                     </div>
                                                     <div>
                                                     </div>
                                                     <div
                                                         *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                                         <div *ngIf="!item.isAdded"
                                                             (click)="addToCart(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                                             class="ellipseBtn  whiteClr positionRelative addToCarLiMd halfViewBtn secGradient">
                                                             <b>
                                                                 <i class="fa fa-shopping-cart"
                                                                     aria-hidden="true"></i>
                                                                 &nbsp;&nbsp; Add to cart</b>
                                                             <div class="addToCartLoader" *ngIf="item.isLoading">
                                                                 <img src="assets/img/spinner.gif">
                                                             </div>
                                                         </div>
                                                         <div class="cartCount" *ngIf="item.isAdded">
                                                             <div class="flex alignCenter contentJustify">
                                                                 <div class="primaryGradient incrementDecrement"
                                                                     (click)="updateCartQuantity(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                                     <i class="fa fa-angle-left"
                                                                         aria-hidden="true"></i>
                                                                 </div>
                                                                 <div class="itemCount">
                                                                     <b>{{item.quantity}}</b>
                                                                 </div>
                                                                 <div class="primaryGradient incrementDecrement"
                                                                     (click)="updateCartQuantity(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                                     <i class="fa fa-angle-right"
                                                                         aria-hidden="true"></i>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">Out of Stock
                                                     </div> -->
                                                 </div>
                                             </div>
                                         </ng-template>
                                     </ng-container>
                                 </owl-carousel-o>
                             </div>
                         </div>
                     </ng-container>
                 </div>
             </div>
         </div>
        </section>

         <!---------RECENTLY VIEW AND FREQUANTLY BROUGHT------->
         <section class="best-offer desktopView animated fadeDown" id="apna-grocery"
         *ngIf="(recentlyView && recentlyView.cmsSectionBO) || (frequentlyView && frequentlyView.cmsSectionBO)">
         <div class="container">
             <div class="row">
                 <div 
                      [ngClass]="{'col-xl-12':recentlyView.cmsSectionBO && recentlyView.cmsSectionBO.noOfRows == 1,'col-xl-6':recentlyView.cmsSectionBO && recentlyView.cmsSectionBO.noOfRows == 2 }">
                     <ng-container *ngIf="recentlyView && recentlyView.cmsSectionBO  && recentlyView.cmsSectionBO.cmsSectionContentBOList
                     && recentlyView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
 
                         <div
                             *ngFor="let pageSection of recentlyView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                             <h3 class="recent-product-title">{{ pageSection.title}}
                             </h3>
                             <div *ngIf="pageSection.itemList && pageSection.itemList.length<4" class="row mb-2">
                              <div *ngFor="let item of pageSection.itemList;let itemIndex=index"
                              [ngClass]="{'col-xl-3':recentlyView.cmsSectionBO && recentlyView.cmsSectionBO.noOfRows == 1,
                              'col-xl-4':recentlyView.cmsSectionBO && recentlyView.cmsSectionBO.noOfRows == 2 }">
                              <div class="card-product-offer single-product">
                                 <div class="productListImage"
                                     [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                     (click)="itemDetail(item)">
                                     <div class="productListImage">
                                         <div class="outOfStock list-offer-outofstock"
                                             *ngIf="!item.isStockAvailable">Out of Stock
                                         </div>
                                         <img class="card-img-top"
                                             src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                             loading="lazy"
                                             onError="this.src='assets/img/brandOnError.png'">
                                     </div>
                                 </div>
                                 <div class="product-offer" *ngIf="item.discPerc">
                                     <p>{{item.discPerc}}% Off</p>
                                 </div>
                                 <div class="card-block-all-product">
                                     <!-- <div class="brandName">{{item.brandName}}</div> -->
                                     <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                         placement="top" show-delay="100">{{item.brandName}}</div>
 
                                     <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                         placement="top" show-delay="500" (click)="itemDetail(item)">
                                         {{item.itemName}}</h5>
                                     <p class="product-weight">
                                         <span
                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                         </span>
                                         <span
                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                     </p>
                                     <div class="style-1">
                                         <del>
                                             <span
                                                 *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                                 {{currencySymbol}} {{item.mrp}}
                                             </span>
                                             <span
                                                 *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                                 {{currencySymbol}} {{item.salePrice}}
                                             </span>
                                             <span
                                                 *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice)">
                                                 {{currencySymbol}} {{item.mrp}}
                                             </span>
                                         </del>
                                         <ins>
                                             <span class="money">
                                                 {{currencySymbol}} {{item.finalPrice}}
                                             </span>
                                         </ins>
                                     </div>
                                     <div>
                                     </div>
                                     <div
                                         *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                         <div *ngIf="!item.isAdded"
                                             (click)="addToCart(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                             class="ellipseBtn  whiteClr positionRelative addToCarLiMd halfViewBtn secGradient">
                                             <b>
                                                 <i class="fa fa-shopping-cart"
                                                     aria-hidden="true"></i>
                                                 &nbsp;&nbsp; Add to cart</b>
                                             <div class="addToCartLoader" *ngIf="item.isLoading">
                                                 <img src="assets/img/spinner.gif">
                                             </div>
                                         </div>
                                         <div class="cartCount" *ngIf="item.isAdded">
                                             <div class="flex alignCenter contentJustify">
                                                 <div class="primaryGradient incrementDecrement"
                                                     (click)="updateCartQuantity(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                     <i class="fa fa-angle-left"
                                                         aria-hidden="true"></i>
                                                 </div>
                                                 <div class="itemCount">
                                                     <b>{{item.quantity}}</b>
                                                 </div>
                                                 <div class="primaryGradient incrementDecrement"
                                                     (click)="updateCartQuantity(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                     <i class="fa fa-angle-right"
                                                         aria-hidden="true"></i>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">Out of Stock
                                     </div> -->
                                 </div>
                             </div>
                              </div>
                             </div>
                             <div *ngIf="pageSection.itemList && pageSection.itemList.length>3">
                                 <owl-carousel-o [options]="apnagrocerySlider">
                                     <ng-container *ngFor="let item of pageSection.itemList;let itemIndex=index">
                                         <ng-template carouselSlide>
                                             <div class="card-product-offer single-product">
                                                 <div class="productListImage"
                                                     [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                                     (click)="itemDetail(item)">
                                                     <div class="productListImage">
                                                         <div class="outOfStock list-offer-outofstock"
                                                             *ngIf="!item.isStockAvailable">Out of Stock
                                                         </div>
                                                         <img class="card-img-top"
                                                             src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                                             loading="lazy"
                                                             onError="this.src='assets/img/brandOnError.png'">
                                                     </div>
                                                 </div>
                                                 <div class="product-offer" *ngIf="item.discPerc">
                                                     <p>{{item.discPerc}}% Off</p>
                                                 </div>
                                                 <div class="card-block-all-product">
                                                     <!-- <div class="brandName">{{item.brandName}}</div> -->
                                                     <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                                         placement="top" show-delay="100">{{item.brandName}}</div>
 
                                                     <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                                         placement="top" show-delay="500" (click)="itemDetail(item)">
                                                         {{item.itemName}}</h5>
                                                     <p class="product-weight">
                                                         <span
                                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                                         </span>
                                                         <span
                                                             *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                                     </p>
                                                     <div class="style-1">
                                                         <del>
                                                             <span
                                                                 *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                                                 {{currencySymbol}} {{item.mrp}}
                                                             </span>
                                                             <span
                                                                 *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                                                 {{currencySymbol}} {{item.salePrice}}
                                                             </span>
                                                             <span
                                                                 *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice)">
                                                                 {{currencySymbol}} {{item.mrp}}
                                                             </span>
                                                         </del>
                                                         <ins>
                                                             <span class="money">
                                                                 {{currencySymbol}} {{item.finalPrice}}
                                                             </span>
                                                         </ins>
                                                     </div>
                                                     <div>
                                                     </div>
                                                     <div
                                                         *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                                         <div *ngIf="!item.isAdded"
                                                             (click)="addToCart(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                                             class="ellipseBtn  whiteClr positionRelative addToCarLiMd halfViewBtn secGradient">
                                                             <b>
                                                                 <i class="fa fa-shopping-cart"
                                                                     aria-hidden="true"></i>
                                                                 &nbsp;&nbsp; Add to cart</b>
                                                             <div class="addToCartLoader" *ngIf="item.isLoading">
                                                                 <img src="assets/img/spinner.gif">
                                                             </div>
                                                         </div>
                                                         <div class="cartCount" *ngIf="item.isAdded">
                                                             <div class="flex alignCenter contentJustify">
                                                                 <div class="primaryGradient incrementDecrement"
                                                                     (click)="updateCartQuantity(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                                     <i class="fa fa-angle-left"
                                                                         aria-hidden="true"></i>
                                                                 </div>
                                                                 <div class="itemCount">
                                                                     <b>{{item.quantity}}</b>
                                                                 </div>
                                                                 <div class="primaryGradient incrementDecrement"
                                                                     (click)="updateCartQuantity(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                                     <i class="fa fa-angle-right"
                                                                         aria-hidden="true"></i>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                     </div>
                                                     <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">Out of Stock
                                                     </div> -->
                                                 </div>
                                             </div>
                                         </ng-template>
                                     </ng-container>
                                 </owl-carousel-o>
                             </div>
                         </div>
                     </ng-container>
                 </div> 
             </div>
         </div>
        </section>

        <!---------recommendationView------->
        <section class="best-offer desktopView animated fadeDown" id="apna-grocery"
          *ngIf="recommendationView && recommendationView.cmsSectionBO">
          <div class="container">
              <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="recommendationView && recommendationView.cmsSectionBO && recommendationView.cmsSectionBO.cmsSectionContentBOList
          && recommendationView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
          <div
              *ngFor="let pageSection of recommendationView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
              <h3 class="recent-product-title">{{ pageSection.title}} </h3>
              <div *ngIf="pageSection.itemList && pageSection.itemList.length<3" class="row mb-2">
                  <div *ngFor="let item of pageSection.itemList;let itemIndex=index" class="col-12 col-lg-5 col-xl-5 col-xxl-5">
                      <div class="card-product-offer single-product">
                          <div class="productListImage" (click)="itemDetail(item)">
                              <div class="productListImage cursorPointer"
                                  [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                  (click)="itemDetail(item)">
                                  <div class="outOfStock grid-outofstock"
                                      *ngIf="!item.isStockAvailable">Out
                                      of Stock
                                  </div>
                                  <img class="card-img-top"
                                      src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                      loading="lazy"
                                      onError="this.src='assets/img/brandOnError.png'">
                              </div>
                          </div>
                          <div class="product-offer" *ngIf="item.discPerc">
                              <p>{{item.discPerc}}% Off</p>
                          </div>
                          <div class="card-block-all-product">
                              <!-- <div class="brandName">{{item.brandName}}</div> -->
                              <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                  placement="top" show-delay="100">{{item.brandName}}</div>
 
                              <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                  placement="top" show-delay="500" (click)="itemDetail(item)">
                                  {{item.itemName}}</h5>
                              <p class="product-weight">
                                  <span
                                      *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                  </span>
                                  <span
                                      *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                              </p>
                              <div class="style-1">
                                  <del>
                                      <span
                                          *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                          {{currencySymbol}} {{item.mrp}}
                                      </span>
                                      <span
                                          *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                          {{currencySymbol}} {{item.salePrice}}
                                      </span>
                                      <span
                                          *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice)">
                                          {{currencySymbol}} {{item.mrp}}
                                      </span>
                                  </del>
                                  <ins>
                                      <span class="money">
                                          {{currencySymbol}} {{item.finalPrice}}
                                      </span>
                                  </ins>
                              </div>
                              <div>
                              </div>
                              <div
                                  *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                  <div *ngIf="!item.isAdded"
                                      (click)="addToCart(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                      class="ellipseBtn  whiteClr positionRelative addToCarLiMd halfViewBtn secGradient">
                                      <b>
                                          <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                          &nbsp;&nbsp; Add to cart</b>
                                      <div class="addToCartLoader" *ngIf="item.isLoading">
                                          <img src="assets/img/spinner.gif">
                                      </div>
                                  </div>
                                  <div class="cartCount" *ngIf="item.isAdded">
                                      <div class="flex alignCenter contentJustify">
                                          <div class="primaryGradient incrementDecrement"
                                              (click)="updateCartQuantity(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                              <i class="fa fa-angle-left" aria-hidden="true"></i>
                                          </div>
                                          <div class="itemCount">
                                              <b>{{item.quantity}}</b>
                                          </div>
                                          <div class="primaryGradient incrementDecrement"
                                              (click)="updateCartQuantity(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                              <i class="fa fa-angle-right" aria-hidden="true"></i>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">Out of Stock
                              </div> -->
                          </div>
                      </div>
                  </div>
                  </div>
              <div *ngIf="pageSection.itemList && pageSection.itemList.length>2">
                  <owl-carousel-o [options]="recomendationSlider">
                      <ng-container *ngFor="let item of pageSection.itemList;let itemIndex=index">
                          <ng-template carouselSlide>
                              <div class="card-product-offer single-product">
                                  <div class="productListImage" (click)="itemDetail(item)">
                                      <div class="productListImage cursorPointer"
                                          [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                          (click)="itemDetail(item)">
                                          <div class="outOfStock grid-outofstock"
                                              *ngIf="!item.isStockAvailable">Out
                                              of Stock
                                          </div>
                                          <img class="card-img-top"
                                              src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}"
                                              loading="lazy"
                                              onError="this.src='assets/img/brandOnError.png'">
                                      </div>
                                  </div>
                                  <div class="product-offer" *ngIf="item.discPerc">
                                      <p>{{item.discPerc}}% Off</p>
                                  </div>
                                  <div class="card-block-all-product">
                                      <!-- <div class="brandName">{{item.brandName}}</div> -->
                                      <div class="brandName text-all-brand" [tooltip]="item.brandName"
                                          placement="top" show-delay="100">{{item.brandName}}</div>
 
                                      <h5 class="text-bold-all-product" [tooltip]="item.itemName"
                                          placement="top" show-delay="500" (click)="itemDetail(item)">
                                          {{item.itemName}}</h5>
                                      <p class="product-weight">
                                          <span
                                              *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                          </span>
                                          <span
                                              *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                      </p>
                                      <div class="style-1">
                                          <del>
                                              <span
                                                  *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp && (item.mrp > item.finalPrice)">
                                                  {{currencySymbol}} {{item.mrp}}
                                              </span>
                                              <span
                                                  *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice && (item.salePrice > item.finalPrice)">
                                                  {{currencySymbol}} {{item.salePrice}}
                                              </span>
                                              <span
                                                  *ngIf="!item.discountPriceType && (item.mrp > item.finalPrice)">
                                                  {{currencySymbol}} {{item.mrp}}
                                              </span>
                                          </del>
                                          <ins>
                                              <span class="money">
                                                  {{currencySymbol}} {{item.finalPrice}}
                                              </span>
                                          </ins>
                                      </div>
                                      <div>
                                      </div>
                                      <div
                                          *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                          <div *ngIf="!item.isAdded"
                                              (click)="addToCart(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex)"
                                              class="ellipseBtn  whiteClr positionRelative addToCarLiMd halfViewBtn secGradient">
                                              <b>
                                                  <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                  &nbsp;&nbsp; Add to cart</b>
                                              <div class="addToCartLoader" *ngIf="item.isLoading">
                                                  <img src="assets/img/spinner.gif">
                                              </div>
                                          </div>
                                          <div class="cartCount" *ngIf="item.isAdded">
                                              <div class="flex alignCenter contentJustify">
                                                  <div class="primaryGradient incrementDecrement"
                                                      (click)="updateCartQuantity(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                      <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                  </div>
                                                  <div class="itemCount">
                                                      <b>{{item.quantity}}</b>
                                                  </div>
                                                  <div class="primaryGradient incrementDecrement"
                                                      (click)="updateCartQuantity(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <!-- <div class="outOfStock" *ngIf="item.isInventory && item.stockInHand<=0">Out of Stock
                                      </div> -->
                                  </div>
                              </div>
                          </ng-template>
                      </ng-container>
                  </owl-carousel-o>
              </div>
          </div>
          </div>
         </div>
     </div>
        </section>

    </ng-container>
</section>

<!----mobile VIEW -->
<section class="homePageSection mobileView" *ngIf="previewDataList && previewDataList.length>0" (scroll)="onScrollOrder($event)">
    <!-- <ng-container> -->

     <span *ngIf="promotedBrandView && promotedBrandView.cmsSectionBO">
                    <div *ngIf="promotedBrandView && promotedBrandView.cmsSectionBO  && promotedBrandView.cmsSectionBO.cmsSectionContentBOList
                    && promotedBrandView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
                        <div *ngFor="let pageSection of promotedBrandView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                            <section class="mobileViewProdList mobileView">

                                <div class="mobileViewScrollingBrand">
                                    <div class="productCart" *ngFor="let brand of pageSection.brandList;let itemIndex=index">
                                            <div class="single-brand">
                                                    <div class="brandListImage" routerLink="/web/ag/products" [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                                                <img class="card-img-top" src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-250,h-150,cm-pad_resize/{{brand.filePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
    </span> 

    <ng-container *ngFor="let preview of previewDataList;let i=index;let last=last;let first=first">
        <!-------HOME PAGE SLIDER------>
        <section class="mt-3 mainSlider animated fadeIn" id="mainSlider" *ngIf="preview?.cmsSlider?.cmsSliderContentList?.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pad0">
                        <br *ngIf="first">
                        <owl-carousel-o [options]="advertisingSlider" *ngIf="preview.cmsSlider.cmsSliderContentList?.length > 1">
                            <ng-container *ngFor="let imgs of preview.cmsSlider.cmsSliderContentList;let i=index">
                                <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                                    <img src="{{dataService.appConstant.IMAGE_URL}}{{imgs.imageFilePath}}" loading="lazy" onError="this.src='assets/img/errorLogo.jpg'"
                                        style="width:100%" (click)="onBannerClick(imgs)">
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </section> 

        <ng-container *ngIf="!preview.cmsSliderUid && preview.cmsSectionBO">
            <div *ngFor="let pageSection of preview.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">

                <!-----MOBILE VIEW CATEGORY-->

                 <section class="mobileView mobileViewCategory" *ngIf="preview.pageContentUid == 'PGC7874776730' ">
                   <div class="container mobileVCatContainer" *ngIf="pageSection.catList?.length > 0">
                        <div class="mobileVsectionHeading mobileView">
                            <div class="sectionHeading">{{preview.title}}</div>
                        </div>
                        <div class="sliderrow row">
                            <div size="4" *ngFor="let category of pageSection.catList" class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 changecolpad">
                                <div class="categorycard" routerLink="/web/ag/products" [queryParams]="{category:category.name,category_uid:category.categoryUid,catIndex:categoryIndex,
                                        parentUid:category.categoryUid, ecommPageUid:ecommPageUid}">
                                    <div class="cateimagdiv">
                                        <img src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-{{imgWidth}},h-{{imgHeight}},cm-pad_resize/{{category.filePath}}"
                                            alt="" class="categoryimg">
                                    </div>
                                    <div class="categoryname">
                                        <strong>{{category.name}}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="divider mobileView"> </div>
                </section> 

                <!-- BRAND SECTION -->
                <section class="shop-brands animated fadeDown" id="brand-slider" [ngClass]="{'mb-5':last}" *ngIf="pageSection.viewType == 1 && pageSection.subViewType == 'SLIDING_ONLY_IMAGE_LIST'">
                     <div class="" *ngIf="pageSection.brandList">
                        <div class="bramd-title desktopView">
                            <h3>{{ preview.title}}</h3>
                        </div>

                        <div class="mobileVsectionHeading mobileView">
                            <div class="sectionHeading">{{preview.title}}</div>
                        </div>

                        <div *ngIf="pageSection.brandList">
                            <div class="sliderrow row">
                                <div size="4" *ngFor='let brand of pageSection.brandList' class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 changecolpad">
                                    <div class="brandcard" routerLink="/web/ag/products" [queryParams]="{brandUid:brand.brandUid,type:'brnd',brandName:brand.brandName,ecommPageUid:ecommPageUid}">
                                        <div class="brandimgdiv">
                                            <img src="{{dataService.appConstant.IMAGE_BASE_URL}}/tr:w-300,h-300,cm-pad_resize/{{brand.filePath}}" alt="" class="brandimg_mob center">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 changecolpad">
                                    <div class=" brandcard">
                                        <div class="brandimgdiv" routerLink="/web/ag/shop-by-brand">
                                            <img src="assets/img/shop_by_view_all-arrow.svg" alt="" class="responsive center brandarrow brandimg_mob">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container" *ngIf="pageSection.catList">
                        <div class="bramd-title desktopView">
                            <h3>{{ preview.title}}</h3>
                        </div>

                        <div class="mobileVsectionHeading mobileView" routerLink="/web/ag/categories">
                            <div class="sectionHeading">{{preview.title}}
                                <span>View All</span>
                            </div>
                        </div>

                        <div *ngIf="pageSection.catList">
                            <owl-carousel-o [options]="advertisingSlider2">
                                <ng-container *ngFor="let category of pageSection.catList">
                                    <ng-template carouselSlide>
                                        <div class="brands" routerLink="/web/ag/products" [queryParams]="{category:category.name,category_uid:category.categoryUid,ecommPageUid:ecommPageUid }">
                                            <img class="card-img-top" src="{{dataService.appConstant.IMAGE_URL}}{{category.filePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>

                    <div class="container" *ngIf="pageSection.itemList">
                        <div class="bramd-title desktopView">
                            <h3>{{ preview.title}}</h3>
                        </div>

                        <div class="mobileVsectionHeading mobileView" routerLink="/web/ag/products">
                            <div class="sectionHeading">{{preview.title}}
                                <span>View All</span>
                            </div>
                        </div>

                        <div *ngIf="pageSection.brandList">
                            <owl-carousel-o [options]="advertisingSlider2">
                                <ng-container *ngFor="let item of pageSection.itemList " (click)="itemDetail(item)">
                                    <ng-template carouselSlide>
                                        <div class="brands">
                                            <img class="card-img-top" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>

                    <div class="divider mobileView" *ngIf="!last"> </div> 
                </section>  
            </div>
        </ng-container>
    </ng-container>

     <!-------HOME TOP PRODUCTS------>

     <div *ngIf="topProductView && topProductView.cmsSectionBO">
        <div *ngIf="topProductView && topProductView.cmsSectionBO  && topProductView.cmsSectionBO.cmsSectionContentBOList
        && topProductView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
            <div *ngFor="let pageSection of topProductView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                <section class="mobileViewProdList mobileView">
                    <div class="mobileVsectionHeading mobileView" routerLink="/web/ag/products">
                        <div class="sectionHeading">{{pageSection.title}}
                            <span>View All</span>
                        </div>
                    </div>
                    <div class="mobileViewScrollingProd">
                        <div class="productCart" *ngFor="let item of pageSection.itemList;let itemIndex=index">
                            <div class="mob-card-product-offer ">
                                <div class="productListImage cursorPointer"
                                [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                 (click)="itemDetail(item)">
                                    <div class="outOfStock mobview-offer-outofstock" *ngIf="!item.isStockAvailable">
                                        Out of Stock</div>
                                    <img class="card-img-top" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                </div>
                                <div class="product-offer" *ngIf="item.discPerc">
                                    <p>{{item.discPerc}}% Off</p>
                                </div>
                                <div class="card-block-all-product">

                                    <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top"
                                    show-delay="100"
                                    >{{item.brandName}}</div>
                                    <h5 class="mob-text-all-product" [tooltip]="item.itemName" placement="top"
                                        show-delay="500" >
                                        {{item.itemName}}</h5>
                                    <p class="product-weight" (click)="itemDetail(item)">
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                        </span>
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                    </p>
                                    <div>
                                    </div>

                                    <div class=" alignCenter">

                                        <div class="style-1">

                                                <del>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice  && (item.salePrice > item.finalPrice)">
                                                        {{currencySymbol}} {{item.salePrice}}
                                                    </span>
                                                    <span *ngIf="!item.discountPriceType  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                </del>

                                                <ins>
                                                    <span class="money prodPrice">
                                                        {{currencySymbol}} {{item.finalPrice}}
                                                    </span>
                                                </ins>

                                        </div>

                                        <div class="w-100  mobileViewAddToCart" *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                            <div *ngIf="!item.isAdded" (click)="addToCart(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex)" class="ellipseBtn  whiteClr positionRelative mProdAddTCart rectangleBannerBtn secGradient">
                                                <b>
                                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    Add to cart</b>
                                                <div class="addToCartLoader" *ngIf="item.isLoading">
                                                    <img src="assets/img/spinner.gif">
                                                </div>
                                            </div>
                                            <div class="cartCount" *ngIf="item.isAdded">
                                                <div class="flex alignCenter contentJustify">
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="itemCount">
                                                        <b>{{item.quantity}}</b>
                                                    </div>
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(topProductView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="divider" *ngIf="!last"> </div>
                </section>
            </div>
        </div>
    </div> 

     <div *ngIf="bestSellerProduct && bestSellerProduct.cmsSectionBO   && bestSellerProduct.cmsSectionBO.cmsSectionContentBOList
        && bestSellerProduct.cmsSectionBO.cmsSectionContentBOList.length > 0">
        <div *ngFor="let pageSection of bestSellerProduct.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
            <section class="mobileViewProdList mobileView" *ngIf="pageSection.viewType == 5  && pageSection.itemList?.length > 0">
                <div class="mobileVsectionHeading mobileView" >
                    <div class="sectionHeading">{{pageSection.title}}
                        <span routerLink="/web/ag/products">View All</span>
                    </div>
                </div>
                <div class="mobileViewScrollingProd">
                    <div class="productCart" *ngFor="let item of pageSection.itemList;let itemIndex=index">
                        <div class="mob-card-product-offer ">
                            <div class="productListImage cursorPointer"
                                [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                (click)="itemDetail(item)">
                                <div class="outOfStock mobview-offer-outofstock" *ngIf="!item.isStockAvailable">
                                    Out of Stock</div>
                                <img class="card-img-top" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                            </div>
                            <div class="product-offer" *ngIf="item.discPerc">
                                <p>{{item.discPerc}}% Off</p>
                            </div>
                            <div class="card-block-all-product">

                                    <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top" show-delay="100">{{item.brandName}}
                                    </div>
                                <h5 class="mob-text-all-product" [tooltip]="item.itemName" placement="top" show-delay="500">
                                    {{item.itemName}}</h5>
                                <p class="product-weight" (click)="itemDetail(item)">
                                    <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                    </span>
                                    <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                </p>
                                <div>
                                </div>

                                <div class=" alignCenter">

                                    <div class="style-1">

                                            <del>
                                                <span *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp  && (item.mrp > item.finalPrice)">
                                                    {{currencySymbol}} {{item.mrp}}
                                                </span>
                                                <span *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice  && (item.salePrice > item.finalPrice)">
                                                    {{currencySymbol}} {{item.salePrice}}
                                                </span>
                                                <span *ngIf="!item.discountPriceType  && (item.mrp > item.finalPrice)">
                                                    {{currencySymbol}} {{item.mrp}}
                                                </span>
                                            </del>


                                            <ins>
                                                <span class="money prodPrice">
                                                    {{currencySymbol}} {{item.finalPrice}}
                                                </span>
                                            </ins>

                                    </div>

                                    <div class="w-100  mobileViewAddToCart" *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                        <div *ngIf="!item.isAdded" (click)="addToCart(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex)" class="ellipseBtn  whiteClr positionRelative mProdAddTCart rectangleBannerBtn secGradient">
                                            <b>
                                                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                Add to cart</b>
                                            <div class="addToCartLoader" *ngIf="item.isLoading">
                                                <img src="assets/img/spinner.gif">
                                            </div>
                                        </div>
                                        <div class="cartCount" *ngIf="item.isAdded">
                                            <div class="flex alignCenter contentJustify">
                                                <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                </div>
                                                <div class="itemCount">
                                                    <b>{{item.quantity}}</b>
                                                </div>
                                                <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(bestSellerProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="divider" *ngIf="!last"> </div>
            </section>
        </div>
    </div> 

    <div *ngIf="websiteProduct && websiteProduct.cmsSectionBO">
        <div *ngIf="websiteProduct && websiteProduct.cmsSectionBO  && websiteProduct.cmsSectionBO.cmsSectionContentBOList
        && websiteProduct.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
            <div *ngFor="let pageSection of websiteProduct.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                <section class="mobileViewProdList mobileView">
                    <div class="mobileVsectionHeading mobileView">
                        <div class="d-flex sectionHeading">
                            <div class=" text-all-offer col-md-9" [tooltip]="pageSection.title" placement="top" show-delay="100">{{pageSection.title}}</div>

                            <div class="col-md-3" routerLink="/web/ag/products">
                                <span>View All</span>
                            </div>
                        </div>

                    </div>
                    <div class="mobileViewScrollingProd">
                        <div class="productCart" *ngFor="let item of pageSection.itemList;let itemIndex=index">
                            <div class="mob-card-product-offer ">
                                <div class="productListImage cursorPointer"
                                [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                (click)="itemDetail(item)">
                                <div class="outOfStock mobview-offer-outofstock" *ngIf="!item.isStockAvailable">
                                    Out of Stock</div>
                                    <img class="card-img-top" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                </div>
                                <div class="product-offer" *ngIf="item.discPerc">
                                    <p>{{item.discPerc}}% Off</p>
                                </div>
                                <div class="card-block-all-product">
                                    <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top"
                                    show-delay="100"
                                    >{{item.brandName}}</div>
                                    <h5 class="mob-text-all-product" [tooltip]="item.itemName" placement="top"
                                        show-delay="500" >
                                        {{item.itemName}}</h5>

                                    <p class="product-weight" (click)="itemDetail(item)">
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                        </span>
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                    </p>
                                    <div>
                                    </div>

                                    <div class=" alignCenter">

                                        <div class="style-1">

                                                <del>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice  && (item.salePrice > item.finalPrice)">
                                                        {{currencySymbol}} {{item.salePrice}}
                                                    </span>
                                                    <span *ngIf="!item.discountPriceType  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                </del>

                                                <ins>
                                                    <span class="money prodPrice">
                                                        {{currencySymbol}} {{item.finalPrice}}
                                                    </span>
                                                </ins>

                                        </div>

                                        <div class="w-100 mobileViewAddToCart" *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0 && item.stockInHand !=null)">
                                            <div *ngIf="!item.isAdded" (click)="addToCart(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex)" class="ellipseBtn  whiteClr positionRelative mProdAddTCart rectangleBannerBtn secGradient">
                                                <b>
                                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    Add to cart</b>
                                                <div class="addToCartLoader" *ngIf="item.isLoading">
                                                    <img src="assets/img/spinner.gif">
                                                </div>
                                            </div>
                                            <div class="cartCount" *ngIf="item.isAdded">
                                                <div class="flex alignCenter contentJustify">
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="itemCount">
                                                        <b>{{item.quantity}}</b>
                                                    </div>
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(websiteProduct.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="divider" *ngIf="!last"> </div>
                </section>
            </div>
        </div>
    </div> 
    <ng-container>
        <!-------HOME PAGE SLIDER------>
         <section class="mainSlider animated fadeIn" id="mainSlider">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pad0 mb-3">
                        <div *ngFor="let preview of previewDataList;let i=index;let last=last;let first=first">
                            <div *ngIf="preview?.cmsSlider?.cmsSliderContentList?.length > 0">
                                <owl-carousel-o [options]="advertisingSlider" *ngIf="preview.cmsSlider.cmsSliderContentList?.length == 1">
                                    <ng-container *ngFor="let imgs of preview.cmsSlider.cmsSliderContentList;let i=index">
                                        <ng-template carouselSlide (someEvent)="onBannerClick(imgs)">
                                            <img src="{{dataService.appConstant.IMAGE_URL}}{{preview.cmsSlider.cmsSliderContentList[0].imageFilePath}}" loading="lazy"
                                                onError="this.src='assets/img/errorLogo.jpg'" style="width:100%">
                                        </ng-template>
                                    </ng-container>
                                </owl-carousel-o>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    </ng-container>
    <div *ngIf="recentlyView && recentlyView.cmsSectionBO">
        <div *ngIf="recentlyView && recentlyView.cmsSectionBO  && recentlyView.cmsSectionBO.cmsSectionContentBOList
        && recentlyView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
            <div *ngFor="let pageSection of recentlyView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                <section class="mobileViewProdList mobileView">
                    <div class="mobileVsectionHeading mobileView" >
                        <div class="sectionHeading">{{pageSection.title}}
                            <span routerLink="/web/ag/products">View All</span>
                        </div>
                    </div>
                    <div class="mobileViewScrollingProd">
                        <div class="productCart" *ngFor="let item of pageSection.itemList;let itemIndex=index">
                            <div class="mob-card-product-offer ">
                                <div class="productListImage cursorPointer"
                                [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                (click)="itemDetail(item)">
                                <div class="outOfStock mobview-offer-outofstock" *ngIf="!item.isStockAvailable">
                                    Out of Stock</div>
                                    <img class="card-img-top" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                </div>
                                <div class="product-offer" *ngIf="item.discPerc">
                                    <p>{{item.discPerc}}% Off</p>
                                </div>
                                <div class="card-block-all-product">
                                    <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top"
                                    show-delay="100"
                                    >{{item.brandName}}</div>
                                    <h5 class="mob-text-all-product" [tooltip]="item.itemName" placement="top"
                                        show-delay="500" >
                                        {{item.itemName}}</h5>
                                    <p class="product-weight">
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                        </span>
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                    </p>
                                    <div>
                                    </div>

                                    <div class=" alignCenter">

                                        <div class="style-1">

                                                <del>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice  && (item.salePrice > item.finalPrice)">
                                                        {{currencySymbol}} {{item.salePrice}}
                                                    </span>
                                                    <span *ngIf="!item.discountPriceType  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                </del>

                                                <ins>
                                                    <span class="money prodPrice">
                                                        {{currencySymbol}} {{item.finalPrice}}
                                                    </span>
                                                </ins>

                                        </div>

                                        <div class="w-100  mobileViewAddToCart" *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                            <div *ngIf="!item.isAdded" (click)="addToCart(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex)" class="ellipseBtn  whiteClr positionRelative mProdAddTCart rectangleBannerBtn secGradient">
                                                <b>
                                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    Add to cart</b>
                                                <div class="addToCartLoader" *ngIf="item.isLoading">
                                                    <img src="assets/img/spinner.gif">
                                                </div>
                                            </div>
                                            <div class="cartCount" *ngIf="item.isAdded">
                                                <div class="flex alignCenter contentJustify">
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="itemCount">
                                                        <b>{{item.quantity}}</b>
                                                    </div>
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(recentlyView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="divider" *ngIf="!last"> </div>
                </section>
            </div>
        </div>
    </div>

    <div *ngIf="recommendationView && recommendationView.cmsSectionBO">
        <div *ngIf="recommendationView && recommendationView.cmsSectionBO  && recommendationView.cmsSectionBO.cmsSectionContentBOList
        && recommendationView.cmsSectionBO.cmsSectionContentBOList.length > 0 ">
            <div *ngFor="let pageSection of recommendationView.cmsSectionBO.cmsSectionContentBOList;let cmsSectionContentIndex = index;">
                <section class="mobileViewProdList mobileView">
                    <div class="mobileVsectionHeading mobileView" routerLink="/web/ag/products">
                        <div class="sectionHeading">{{pageSection.title}}
                            <span>View All</span>
                        </div>
                    </div>
                    <div class="mobileViewScrollingProd">
                        <div class="productCart" *ngFor="let item of pageSection.itemList;let itemIndex=index">
                            <div class="mob-card-product-offer ">
                                <div class="productListImage cursorPointer"
                                [ngClass]="{'outofstock-img': !item.isStockAvailable}"
                                 (click)="itemDetail(item)">
                                    <div class="outOfStock mobview-offer-outofstock" *ngIf="!item.isStockAvailable">
                                        Out of Stock</div>
                                    <img class="card-img-top" src="{{dataService.appConstant.IMAGE_URL}}{{item.imageFilePath}}" loading="lazy" onError="this.src='assets/img/brandOnError.png'">
                                </div>
                                <div class="product-offer" *ngIf="item.discPerc">
                                    <p>{{item.discPerc}}% Off</p>
                                </div>
                                <div class="card-block-all-product">

                                    <div class="brandName text-all-brand" [tooltip]="item.brandName" placement="top"
                                    show-delay="100"
                                    >{{item.brandName}}</div>
                                    <h5 class="mob-text-all-product" [tooltip]="item.itemName" placement="top"
                                        show-delay="500" >
                                        {{item.itemName}}</h5>
                                    <p class="product-weight" (click)="itemDetail(item)">
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeValue}}
                                        </span>
                                        <span *ngIf="item.itemVarientBoList?.length > 0">{{item.itemVarientBoList[0].attributeName}}</span>
                                    </p>
                                    <div>
                                    </div>

                                    <div class=" alignCenter">

                                        <div class="style-1">

                                                <del>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '2' && item.mrp  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                    <span *ngIf="item.discountPriceType && item.discountPriceType == '1' &&  item.salePrice  && (item.salePrice > item.finalPrice)">
                                                        {{currencySymbol}} {{item.salePrice}}
                                                    </span>
                                                    <span *ngIf="!item.discountPriceType  && (item.mrp > item.finalPrice)">
                                                        {{currencySymbol}} {{item.mrp}}
                                                    </span>
                                                </del>

                                                <ins>
                                                    <span class="money prodPrice">
                                                        {{currencySymbol}} {{item.finalPrice}}
                                                    </span>
                                                </ins>

                                        </div>

                                        <div class="w-100  mobileViewAddToCart" *ngIf="!item.isInventory || (item.isInventory && item.stockInHand>0)">
                                            <div *ngIf="!item.isAdded" (click)="addToCart(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex)" class="ellipseBtn  whiteClr positionRelative mProdAddTCart rectangleBannerBtn secGradient">
                                                <b>
                                                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    Add to cart</b>
                                                <div class="addToCartLoader" *ngIf="item.isLoading">
                                                    <img src="assets/img/spinner.gif">
                                                </div>
                                            </div>
                                            <div class="cartCount" *ngIf="item.isAdded">
                                                <div class="flex alignCenter contentJustify">
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex,'decrease')">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="itemCount">
                                                        <b>{{item.quantity}}</b>
                                                    </div>
                                                    <div class="primaryGradient incrementDecrement" (click)="updateCartQuantity(recommendationView.previewListIndex,cmsSectionContentIndex,itemIndex,'increase')">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="divider" *ngIf="!last"> </div>
                </section>
            </div>
        </div>
    </div>
</section>

<!----SKELETON LOADER-->
<section *ngIf="isHomeSectionLoading">
    <div class="container-fluid skeletonSection">
        <br>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 colBtmMrg" *ngFor="let item of skeletonSection">
                <div class="prod--wrapper">
                    <div class="prod--col prod--img">
                        <div id="productImage" class="prod--img-graphic skeleton-loader"></div>
                    </div>
                    <div class="prod--col prod--details">
                        <div class="prod--row prod--name w76">
                            <span id="productName" class="prod--name-text skeleton-loader"></span>
                        </div>
                        <div class="prod--row prod--description w87">
                            <span id="productId" class="prod--description-text skeleton-loader"></span>
                        </div>
                        <div class="prod--row prod--description">
                            <span id="productId" class="prod--description-text skeleton-loader"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
